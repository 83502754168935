<template>
    <div class="header">
      <div class="name">Luc Celny</div>
      <div class="title">Software Developer</div>
    </div>
  </template>
  
  <script setup>

  </script>
  
  <style scoped>
.header {
    padding: 50px 50px 20px 50px;
}
.header .name {
    font-size: 46px;
    color: rgb(57, 126, 168);
}
.header .title {
    font-size: 30px;
    color: rgb(113, 113, 113);
}
  </style>
  