<template>
  <HeaderVue />
  <ContactsPane />
  <WorkExperience />
  <FooterVue />
</template>

<script>
import HeaderVue from './components/HeaderVue.vue';
import ContactsPane from './components/ContactsPane.vue'
import WorkExperience from './components/WorkExperience.vue';
import FooterVue from './components/FooterVue.vue';

export default {
  name: 'App',
  components: {
    HeaderVue,
    ContactsPane,
    WorkExperience,
    FooterVue
  }
}
</script>

<style>
#app {
  font-family: sans-serif;
  max-width: 1200px;
  margin: auto;
}
</style>
