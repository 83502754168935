<template>
  <div class="project">
    <div class="project-header">
      <div class="title-icon">
        <div class="title">{{ props.title }} &nbsp;</div>
        <template v-for="icon in props.icons" :key="icon">
          <div class="icon">{{ icon }}</div>
          &nbsp;
        </template>
      </div>
      <div class="timespan">{{ props.timespan }}</div>
    </div>
    <div class="description">
      {{ props.description }}
      <div class="key-points">Responsibilities:</div>
      <ul>
        <li v-for="resp in props.responsibilities" :key="resp">{{ resp }}</li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps([
  "title",
  "icons",
  "timespan",
  "description",
  "responsibilities",
]);
</script>

<style scoped>
.project {
    padding: 10px 0;
}
.project .project-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
@media (max-width: 500px) {
  .project .project-header {
    flex-direction: column;
  }
}
.project .title-icon{
    display: flex;
    align-items: center;
    justify-content: left;
}
.title {
    color: rgb(113, 113, 113);
}
.timespan {
    color: rgb(113, 113, 113);
}
.description {
    padding: 10px;
    font-size: 15px;
}
.key-points {
    color: rgb(57, 126, 168);
    font-weight: bold;
    margin-top: 9px;
}
.icon {
    background-color: rgb(57, 126, 168);
    color: white;
    border-radius: 10%;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    min-width: 30px;
}
</style>