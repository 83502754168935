<template>
  <div class="work-experience">
    <div class="work-experience-header">WORK EXPERIENCE</div>

    <WorkPlace
      :projects="exploranceProjects"
      workplace="Explorance"
      timespan="2019 - 2024"
      location="Montreal, Qc"
    />

    <WorkPlace
      :projects="personalProjects"
      workplace="Personal Projects"
      timespan="2023 - Present"
      location="Longueuil, Qc"
    />
  </div>
</template>

<script setup>
import WorkPlace from "./WorkPlace.vue";

const personalProjects = [
  {
    title: "Multiplayer Board Games APP",
    icons: ["Azure", "SignalR", "Kubernetes", "Redis"],
    timespan: "March 2023 - Present",
    description:
      "A dynamic full-stack web application leveraging SignalR for real-time server-to-client updates, as part of a self-initiated learning project to enhance full-stack development skills.",
    responsibilities: [
      "Focus on learning",
      "Full stack project from concept to live hosting",
      "Containerized with Docker and hosted in Azure Kubernetes Service",
    ],
  },
];

const xProjectBlue = {
  title: "Custom Survey Tool",
  icons: ["C#", "SQL", ".Net"],
  timespan: "May 2022 - March 2023",
  description:
    "A comprehensive People Insights platform, enabling institutions to craft, distribute, and aggregate surveys in a unified system culminating in digestible and actionable data.",
  responsibilities: [
    "Refactored API to be RESTful",
    "Added new features and endpoints",
    "Wrote new stored procedures",
    "Fixed bugs",
    "Implemented new UI designs",
  ],
};

const xProjectResourceManager = {
  title: "Language Manager SPA",
  icons: ["Vue.js", "Vuex", "Cypress"],
  timespan: "Oct 2021 - May 2022",
  description:
    "A user-centered SPA designed to streamline multilingual resource management for in-house products. In-house projects are divided by version and release and support over 20 languages. Translations can be previewed by leveraging the Google translate API.",
  responsibilities: ["Implemented UI designs", "Wrote unit tests and E2E tests", "Secured app with OAuth 2.0", "Set up Google API translate feature"],
};

const XProjectAnnotation = {
  title: "Data Entry SPA",
  icons: ["Angular", "Typescript", "Entity Framework"],
  timespan: "Sept 2019 - Oct 2021",
  description:
    "An interface for a machine learning project, designed to display text alongside context-specific lists of annotations. This application also includes a robust Admin page for project and user management, complemented by a dedicated Quality Assurance (QA) page to oversee annotations.",
  responsibilities: [
    "Implemented Angular SPA",
    "Wrote Functional tests",
    "Optimized graph traversal algorithm for annotation menu",
  ],
};

const XProjectDashboard = {
  title: "Data Visualization Dashboard",
  icons: ["Angular", "Typescript", ".Net", "SQL"],
  timespan: "March 2023 - March 2024",
  description: "A data visualization platform for clients to create dynamic and fully customized representations of their data. This application makes heavy use of Highcharts with Bar charts, Scatter Plots, Pie charts and more. Data is organized in most informative metrics such as mean, median and Net Promoter scale.",
  responsibilities: [
    "Updated project from Angular 12 to Angular 17",
    "Added features, including implementing Highcharts, fillout forms, RTL UI support",
    "Refactored code from Proof of Concept to production quality following best practices"
  ],
}

const exploranceProjects = [XProjectDashboard, xProjectBlue, xProjectResourceManager, XProjectAnnotation];
</script>

<style scoped>
.work-experience {
  padding: 30px 50px;
}
.work-experience-header {
  font-size: 20px;
  color: rgb(57, 126, 168);
  font-weight: bold;
  border-bottom: 1px solid rgb(57, 126, 168);
  /* margin-bottom: 20px; */
}

@media (max-width: 500px) {
  .work-experience {
    padding: 0;
  }
}
</style>
