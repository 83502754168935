<template>
<div class="divider"></div>

<div class="tools-education-languages">
  <div class="footer-column">
    <div class="column-header">Programming Tools</div>
    <div class="column-body">
      <div class="list">
        <div class="icon">.Net</div>
        <div class="icon">Vue.js</div>
        <div class="icon">Angular</div>
        <div class="icon">C#</div>
        <!-- <div class="icon small-txt" >Entity Framework</div> -->
        <div class="icon">Typescript</div>
        <div class="icon">Azure</div>
        <div class="icon">Docker</div>
        <div class="icon">SQL</div>
        <div class="icon">Cypress</div>
      </div>
    </div>
  </div>
  <div class="footer-column footer-education">
    <div class="column-header">Education</div>
    <div class="column-body">
      <div class="education">
        <div class="certification">Bachelor of Computer Science</div>
        <div class="establishment-timespan">
          <div class="establishment">Concordia University</div>
          <div class="timespan">2015 - 2018</div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-column">
    <div class="column-header language-header">
      Spoken & Written Languages
    </div>
    <div class="column-body">
      <div class="languages">
        <div class="language">
          <div class="name">French</div>
          <div class="proficiency">Full Professional Proficiency</div>
        </div>
        <div class="language">
          <div class="name">English</div>
          <div class="proficiency">Full Professional Proficiency</div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<style scoped>
.divider{
    margin: 0 0px 25px 0px;
    border-bottom: 1px solid black;
    /* border-bottom: 1px solid rgb(57, 126, 168); */
}

.tools-education-languages {
/*     display: flex;
    align-items: center;
    justify-content: space-between; */
    padding: 0 50px;
    display: flex;
    align-items: top;
    justify-content: space-between;
}
@media (max-width: 500px) {
  .tools-education-languages {
    flex-direction: column;
  }
}
.footer-column{
    flex: 1;
    text-align: center;
}
.footer-education{
    padding: 0 10px;

}
.column-header{
    font-size: 20px;
    color: rgb(57, 126, 168);
    font-weight: bold;
    height: 40px;
}
.language-header{
    font-size: 18px;
}
.column-body .list{
    display: flex;
    flex-wrap: wrap;
}
.column-body .icon{
    margin: 5px;
    flex: 1 1 25%;
}
.icon {
    background-color: rgb(57, 126, 168);
    color: white;
    border-radius: 10%;
    font-size: 12px;
    padding: 4px;
    text-align: center;
    min-width: 30px;
}
.timespan, .proficiency {
    color: rgb(113, 113, 113);
}
</style>