<template>
  <div class="workplace">
    <div class="name-location-timespan">
      <div class="name">{{ props.workplace }}</div>
      <div class="timespan-location">
        <div>{{ props.timespan }}</div>
        <div class="location">&nbsp; | {{ props.location }}</div>
      </div>
    </div>

    <div class="workplace-projects">
      <div
        v-if="props.workplace != 'Personal Projects'"
        class="workplace-projects-header"
      >
        Workplace Projects:
      </div>

      <ul class="projects-list">
        <li v-for="project in props.projects" :key="project.title">
          <WorkPlaceProject
            :title="project.title"
            :icons="project.icons"
            :timespan="project.timespan"
            :description="project.description"
            :responsibilities="project.responsibilities"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import WorkPlaceProject from "./WorkPlaceProject.vue";
import { defineProps } from "vue";

const props = defineProps(["projects", "workplace", "timespan", "location"]);
</script>

<style scoped>
.workplace {
  border: 2px solid rgb(57, 126, 168);
  border-radius: 5px;
  margin-top: 20px;
}
.name-location-timespan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(57, 126, 168);
  color: white;
  padding: 5px 5px;
}
.timespan-location {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.projects-list {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-right: 5px;
}
.workplace-projects-header {
  /* color: rgb(57, 126, 168); */
  border-bottom: 1px solid rgb(57, 126, 168);

  font-size: 14px;
  padding: 10px 5px;
}
</style>
