<template>
  <div class="contact">
    <div>
      <font-awesome-icon class="icon-style" icon="fa-solid fa-envelope" />luccelny@gmail.com
    </div>
    <div><font-awesome-icon class="icon-style" icon="fa-solid fa-mobile-screen" />514-975-9437</div>
    <div>
      <font-awesome-icon class="icon-style" icon="fa-solid fa-location-dot" />Saint-Hubert, Canada
    </div>
    <div>
      <font-awesome-icon class="icon-style" icon="fa-brands fa-linkedin" />linkedin.com/in/luc-celny
    </div>
  </div>
</template>

<script setup></script>

<style scoped>
.contact {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 7%;
  font-size: 13px;
}
.contact .icon-style {
  color: rgb(57, 126, 168);
  padding: 0 5px;
  font-size: 14px;
}
@media (max-width: 500px) {
  .contact {
    flex-direction: column;
  }
}
</style>
